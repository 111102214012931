<template>
  <div>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">ADD-ON</span>
        </div>
        <div class="v-modal-body pr-1">
          <div class="v-modal-layout pt-2 pr-3" style="max-height: 430px;overflow-y: auto;">
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary font-600 pl-1"
                  v-if="addons.length > 1"
                >{{ totalAddons }} Add-Ons</div>
                <div v-else class="list-title text-secondary font-600">{{ totalAddons }} Add-On</div>
              </div>
              <div class="group mb-3 pl-1 pr-1">
                <input
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  v-model="search_key"
                  @input="getAddonsForSection('search_key')"
                />
              </div>
              <div v-if="totalAddons" style="text-align: right;">
                <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{totalAddons}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button
                    :disabled="count <=0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="getAddonsForSection('prev')"
                    type="button"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-left"></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button
                    :disabled="end >= totalAddons"
                    :class="{'disabled':ajaxCallInProgress}"
                    type="button"
                    v-on:click="getAddonsForSection('next')"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-right"></i>
                  </button>
                </el-tooltip>
              </div>
              <div class style="border: 1px solid #a8adb4 !important;border-radius: 7.5px;">
                <table class="table productTable table-scroll">
                  <thead class="products-variants-table">
                    <tr>
                      <th
                        style="width: 1%;border-radius: 7.5px 0px 0px 0px;border-collapse: separate;flex:0 0 50px !important"
                      ></th>
                      <th>Name</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height:20px!important;" v-if="ajaxCallInProgress">
                      <td colspan="6" class="text-center mt-5" style="justify-content: center;">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="addons.length == 0 && !ajaxCallInProgress">
                      <td colspan="6" class="text-center justify-content-center" v-if="search_key">
                        <strong>No Add-On Found</strong>
                      </td>
                      <td colspan="6" class="text-center justify-content-cente" v-else>
                        <strong>No Add-On</strong>
                      </td>
                    </tr>
                    <tr v-for="addon in addons" :key="addon._id">
                      <td style="width:1% !important;flex:0 0 50px !important">
                        <img
                          v-if="addon.image_url"
                          class="avatar avatar-trans mx-2"
                          :src="addon.image_url"
                        />
                        <div v-else>
                          <avatar
                            :username="addon.name.replace(/ .*/,'')"
                            :src="addon.image_url"
                            :size="40"
                            :rounded="true"
                            color="#fff"
                          ></avatar>
                        </div>
                      </td>
                      <td class="d-flex">
                        <div
                          class="showType"
                          v-if="addon.type_id.name == 'N/A'"
                          :class="{'veg':addon.type_id.name == 'Veg','nonveg':addon.type_id.name == 'Non-Veg','egg':addon.type_id.name == 'Egg','pescatarian':addon.type_id.name == 'Pescatarian'}"
                        >
                          <div></div>
                        </div>
                        <el-tooltip
                          v-else
                          class="item"
                          effect="dark"
                          :content="addon.type_id.name"
                          placement="top"
                        >
                          <div
                            class="showType"
                            :class="{'veg':addon.type_id.name == 'Veg','nonveg':addon.type_id.name == 'Non-Veg','egg':addon.type_id.name == 'Egg','pescatarian':addon.type_id.name == 'Pescatarian'}"
                          >
                            <div></div>
                          </div>
                        </el-tooltip>
                        <span
                          :title="addon.name"
                          style="width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;"
                        >{{addon.name}}</span>
                      </td>
                      <td>{{addon.quantity}}</td>
                      <td>{{addon.formatted_price}}</td>
                      <td>
                        <el-switch
                          v-model="addon.is_available"
                          @change="addonStatus(addon.is_available,addon._id)"
                        ></el-switch>
                      </td>
                      <td>
                        <el-tooltip class="item" effect="dark" content="Edit Addon" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            style="padding: 1.4rem .2rem;"
                            @click="editAddon(addon._id)"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <button type="submit" class="btn btn-secondary btn-smm" @click="closeAddon()">CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
    <edit-addon
      modal_name="addon-edit-section"
      :byAddonSection="byAddonSection"
      @showAddonSection="showAddonSection()"
      :sectionId="sectionId"
    ></edit-addon>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
const EditAddon = () => import('./EditAddon')
import { EventBus } from './eventBus/nav-bar-event'
export default {
  props: ['modal_name', 'sectionId'],
  data() {
    return {
      ajaxCallInProgress: false,
      addons: [],
      search_key: '',
      addonId: '',
      byAddonSection: false,
      count: 0,
      limit: 10,
      skip: 0,
      start: 1,
      end: 0,
      totalAddons: 0
    }
  },
  methods: {
    closeAddon() {
      this.addons = []
      this.search_key = ''
      this.count = 0
      this.limit = 10
      this.skip = 0
      this.start = 1
      this.end = 0
      this.totalAddons = 0
      this.$modal.hide(this.modal_name)
    },
    editAddon(addonId) {
      this.$modal.hide(this.modal_name)
      this.byAddonSection = true
      this.search_key = ''
      EventBus.$emit('addonDetailSection', addonId)
      this.$modal.show('addon-edit-section')
    },
    showAddonSection() {
      this.getAddonsForSection()
      this.$modal.show(this.modal_name)
    },
    addonStatus(status, addonId) {
      this.$http
        .post('/add_ons/addon/edit', {
          _id: addonId,
          is_available: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success('Addon Status Updated Successfully')
          } else {
            this.$toasted.global.error('Cannot Update Add-On Status')
          }
        })
    },
    getAddonsForSection(param) {
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'search_key') {
        this.count = 0
      }
      this.ajaxCallInProgress = true
      this.addons = []
      this.$http
        .post('/add_ons/get_addons_by_section', {
          section_id: this.sectionId,
          search_key: this.search_key,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.ajaxCallInProgress = false
            this.addons = res.data.response
            this.totalAddons = res.data.total_addons
            this.start = this.count * this.limit + 1
            this.end =
              this.count * this.limit + this.limit < this.totalAddons
                ? this.count * this.limit + this.limit
                : this.totalAddons
          }
        })
    }
  },
  components: {
    Avatar,
    EditAddon
  },
  mounted() {
    EventBus.$on('addonBySectionId', sectionId => {
      this.ajaxCallInProgress = true
      this.$http
        .post('/add_ons/get_addons_by_section', {
          section_id: sectionId,
          search_key: this.search_key,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.ajaxCallInProgress = false
            this.addons = res.data.response
            this.totalAddons = res.data.total_addons
            this.start = this.count * this.limit + 1
            this.end =
              this.count * this.limit + this.limit < this.totalAddons
                ? this.count * this.limit + this.limit
                : this.totalAddons
          }
        })
    }),
      EventBus.$on('saveByAddonSection', sectionId => {
        if ((this.byAddonSection = true)) {
          this.ajaxCallInProgress = true
          this.$http
            .post('/add_ons/get_addons_by_section', {
              section_id: sectionId,
              search_key: this.search_key,
              skip: this.count * this.limit,
              limit: this.limit
            })
            .then(res => {
              if (res.data.status_id == 1) {
                this.ajaxCallInProgress = false
                this.addons = res.data.response
                this.totalAddons = res.data.total_addons
                this.start = this.count * this.limit + 1
                this.end =
                  this.count * this.limit + this.limit < this.totalAddons
                    ? this.count * this.limit + this.limit
                    : this.totalAddons
                this.$modal.show(this.modal_name)
              }
            })
        }
      })
  },
  destroyed() {
    EventBus.$off('addonBySectionId')
    EventBus.$off('saveByAddonSection')
  }
}
</script>
<style scoped>
thead.products-variants-table {
  background-color: #00448b;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
thead.products-variants-table th {
  color: #fff !important;
  font-weight: 600 !important;
  padding: 8px 10px !important;
}
.table td {
  padding: 1rem 1rem !important;
  font-weight: 600;
}
table.productTable {
  border-radius: 12px;
  font-weight: 600;
}
table.productTable tbody {
  max-height: 260px !important;
}
table.productTable tbody tr {
  border-bottom: 1px solid #a8adb4;
}
table.productTable tbody tr:last-child {
  border-bottom: none !important;
}
table.productTable tbody tr:last-child() {
  border-bottom: none !important;
}
.vue-avatar--wrapper.modalavatar {
  display: inline-block !important;
}
.avatar {
  background: #00448b;
}
.vue-avatar--wrapper {
  margin-left: 5px !important;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
</style>
